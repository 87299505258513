import Product from './Product';

export interface PagedProductList {
  start: number,
  perPage: number,
  total: number,
  items: Product[]
}

export default interface ProductState {
  breadcrumbs: {
    routes: any[],
    name: string
  },
  current: any,
  current_options: any,
  current_configuration: any,
  parent: any,
  list: PagedProductList,
  original: any,
  related: { [key: string]: Product[] },
  offlineImage: any,
  current_custom_options: any,
  current_bundle_options: any,
  custom_options_validators: any,
  productLoadStart: number,
  productLoadPromise: Promise<any> | null,
  productGallery: any,
  discountProducts: any[],
  topProducts: any[],
  newProducts: any[],
  recommendedProducts: any[],
  characteristicAttributes: any,
  characteristicAttributeCodes: any,
  topCharacteristicAttributeCodes: any,
  productAttributeLabelMap: Record<number, AttributeOption>,
  inStockProductVariants: any,
  varusPerfectProducts: any
}

export interface AttributeOption {
  value: string,
  label: string,
  sort_order: number
}

export interface AttributeOptionItem {
  label: string,
  value: number
}

export enum ProductVisibility {
  NotVisibleIndividually = 1,
  Catalog = 2,
  Search = 3,
  CatalogSearch = 4
}
