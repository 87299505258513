<template>
  <div class="a-totals-placeholder">
    <svg width="100%" height="166" fill="none" preserveAspectRatio="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="100%" height="39" rx="5" fill="#EEEEEE"/>
      <rect y="49" width="100%" height="33" rx="5" fill="#EEEEEE"/>
      <rect y="92" width="100%" height="27" rx="5" fill="#EEEEEE"/>
      <rect y="129" width="100%" height="27" rx="5" fill="#EEEEEE"/>

      <animate
          attributeType="CSS"
          attributeName="opacity"
          from="1"
          to=".3"
          dur="2s"
          values="1; .3; 1"
          keyTimes="0; 0.5; 1"
          repeatCount="indefinite"
      />
    </svg>
  </div>
</template>

<script>
export default {
  name: 'ATotalsPlaceholder'
};
</script>

<style lang="scss">
.a-totals-placeholder {
  svg {
    path, rect {
      fill: var(--placeholder-color)
    }
  }
}
</style>
